import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FormProvider } from '#/components/shared/hook-form';
import { RHFCode } from '#/components/shared/hook-form/RHFCodes';
import { useOtpVerify } from '#/api/userQueries';
import useAuth from '#/hooks/useAuth';
import useLocales from '#/hooks/useLocales';

type FormValuesProps = {
  otp: string;
};

export default function VerifyCodeForm() {
  const { initialize } = useAuth();
  const { translate } = useLocales();

  const { mutateAsync: verifyOTP } = useOtpVerify();
  const { state } = useLocation();

  const VerifyCodeSchema = Yup.object().shape({
    otp: Yup.string()
      .min(6, String(translate('validations.otpMinimum')))
      .required(String(translate('validations.otpCode'))),
  });

  const defaultValues = {
    otp: '',
  };

  const methods = useForm({
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
  } = methods;

  const disabled = !isDirty || isSubmitting || watch('otp').length < 6;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await verifyOTP({
        otp: data.otp,
        email: state.email,
      });
      await initialize(response.data.access);
      toast.success(String(translate('toast_notifications.success.otpCode')));
    } catch (error) {
      console.error(error);
      toast.error(String(translate('toast_notifications.error.otpCode')));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFCode name="otp" />

        <LoadingButton
          aria-label="verify"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={disabled}
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          <Typography>
            {String(translate('global.formLabels.verify'))}
          </Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
